
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import { ReminderStoreMixin } from "@/mixins/Stores/ReminderStoreMixin";
import FormMixin from "@/mixins/Crud/FormMixin";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";
import { Reminder } from "@/config/Modules";
import IReminder from "@/interfaces/IReminder";

@Component({
  components: { DefaultForm },
})
export default class ReminderForm extends mixins(
  FormMixin,
  ReminderStoreMixin
) {
  @Prop({ required: false, default: "create" })
  protected mode!: string;

  @Prop({ required: true })
  protected refDate!: Date;

  @Prop({ required: false, default: null })
  protected reminder!: IReminder | null;

  @Prop({ required: false, default: null })
  protected infoTextProposal!: string | null;

  protected infoText: string | null = null;

  @Watch("reminder", { immediate: true, deep: true })
  protected onReminderChanged(reminder: IReminder | null): void {
    console.debug("remidner changed (in form)", reminder);
    if (reminder) {
      this.setFormValues(reminder);
    }
  }

  @Watch("infoTextProposal", { immediate: true })
  protected onInfoTextProposalChanged(infoTextProposal: string | null): void {
    console.debug(
      "infotext proposal changed",
      infoTextProposal,
      infoTextProposal?.length,
      this.mode
    );
    if (
      infoTextProposal &&
      this.mode === "create" &&
      infoTextProposal.length > 3
    ) {
      this.infoText = this.infoTextProposal;
    }
  }

  protected get firstRemindDate(): Date {
    return this.$moment(this.refDate)
      .subtract(Reminder.config.first_remind_days_before, "days")
      .format(DEFAULT_DATE_FORMAT);
  }

  protected get secondRemindDate(): Date {
    return this.$moment(this.refDate)
      .subtract(Reminder.config.second_remind_days_before, "days")
      .format(DEFAULT_DATE_FORMAT);
  }

  public getFormValues(): IReminder {
    if (this.reminder) {
      return {
        ...this.reminder,
        info_text: this.infoText ?? "",
      };
    }
    return {
      info_text: this.infoText ?? "",
    };
  }

  protected setFormValues(reminder: IReminder): void {
    this.infoText = reminder?.info_text ?? null;
  }

  init(): void {}
}
