
import { Component, Prop, Vue } from "vue-property-decorator";

export interface IInfoData {
  label: string;
  text: string;
}

@Component({
  components: {},
})
export default class InfoTable extends Vue {
  @Prop({ required: true })
  protected infoData!: Array<IInfoData>;
}
