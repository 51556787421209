
import { Component, Prop, Vue } from "vue-property-decorator";
import IContract from "@/interfaces/IContract";
import InfoTable, { IInfoData } from "@/components/common/InfoTable.vue";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from "@/config/Formats";
import Str from "@/helper/Str";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: { InfoTable },
})
export default class CustomerContractShow extends Vue {
  @Prop({ required: true })
  protected contract!: IContract;

  protected get contractInfoData(): Array<IInfoData> {
    if (this.contract) {
      return [
        {
          label: Str.capitalize(
            String(this.$t("modules.customer.contract.starts_at"))
          ),
          text: this.contract?.starts_at
            ? this.$moment(new Date(this.contract.starts_at)).format(
                DEFAULT_DATE_FORMAT
              )
            : "",
        },
        {
          label: Str.capitalize(
            String(this.$t("modules.customer.contract.ends_at"))
          ),
          text: this.contract?.ends_at
            ? this.$moment(new Date(this.contract.ends_at)).format(
                DEFAULT_DATE_FORMAT
              )
            : "",
        },
        {
          label: Str.capitalize(String(this.$t("general.created_by"))),
          text: this.contract.created_by?.name,
        },
        {
          label: Str.capitalize(String(this.$t("general.created_at"))),
          text: this.contract.created_at
            ? this.$moment(new Date(this.contract.created_at ?? "")).format(
                DEFAULT_DATE_TIME_FORMAT
              )
            : "",
        },
        {
          label: Str.capitalize(String(this.$t("general.updated_by"))),
          text: this.contract.updated_by?.name,
        },
        {
          label: Str.capitalize(String(this.$t("general.updated_at"))),
          text: this.contract.updated_at
            ? this.$moment(new Date(this.contract.updated_at ?? "")).format(
                DEFAULT_DATE_TIME_FORMAT
              )
            : "",
        },
      ];
    }
    return [];
  }
}
