
import { Component, Prop, Vue } from "vue-property-decorator";
import IMedia from "@/interfaces/IMedia";
import AppConfig from "@/config/AppConfig";
import PdfView from "@/components/media/PdfView.vue";
import { ITransferProgress } from "@/services/ApiService";

@Component({
  computed: {
    AppConfig() {
      return AppConfig;
    },
  },
  components: { PdfView },
})
export default class Documents extends Vue {
  protected previewImgModalActive = false;
  protected previewImgUrl = "";
  protected previewImgFilename = "";

  protected showDocumentBlobUrl = "";
  protected showDocumentModalActive = false;

  protected documentLoadingUuid = "";
  protected documentLoadingProgress = 0;

  @Prop({ required: true })
  protected documents!: Array<IMedia> | null;

  protected download(url: string, filename: string): void {
    this.$apiService.download(url, {
      filename: filename,
    });
  }

  protected destroy(document: IMedia): void {
    this.$apiService
      .delete(document.original_url, {}, true)
      .then((response: any) => {
        this.$emit("document-removed", document);
      });
  }

  protected onPreviewImgClicked(
    previewImgUrl: string,
    previewImgFilename: string
  ): void {
    this.previewImgUrl = previewImgUrl;
    this.previewImgFilename = previewImgFilename;
    this.previewImgModalActive = true;
  }

  protected onShowDocumentClicked(document: IMedia) {
    this.documentLoadingUuid = document.uuid;
    this.documentLoadingProgress = 0;
    this.$apiService
      .download(
        document.original_url,
        {
          filename: document.file_name,
        },
        true,
        (progress: ITransferProgress) => {
          this.documentLoadingProgress = progress.percentage;
        }
      )
      .then((blobUrl: any) => {
        this.showDocumentBlobUrl = blobUrl;
        this.showDocumentModalActive = true;
      })
      .finally(() => {
        this.documentLoadingUuid = "";
      });
  }
}
