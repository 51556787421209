
import { Component, Prop, Vue } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  components: { pdf },
})
export default class PdfView extends Vue {
  @Prop({ required: true })
  protected pdfBlobUrl!: string | null;

  @Prop({ required: false, default: false })
  protected hideZoom!: boolean;

  protected buttonSize = "is-small";
  protected buttonType = "is-outlined";

  protected currentPage = 1;
  protected totalPages = 0;
  protected zoom = 0.5;

  protected loadedRatio = 0;

  protected prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  protected nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  protected changeZoom(delta: number) {
    // You can adjust the zoom factor based on your requirements
    this.zoom += delta;
  }

  protected onProgressChanged(value: number) {
    this.loadedRatio = value;
  }
}
