
import { Component, Watch } from "vue-property-decorator";
import { MIME_TYPES } from "@/config/Consts";
import { mixins } from "vue-class-component";
import { HandlesUploads } from "@/components/media/mixin/HandlesUploads";

@Component({
  components: {},
})
export default class Upload extends mixins(HandlesUploads) {
  protected get infoText(): string {
    let ret: Array<string> = [];

    if (this.accept !== "") {
      ret.push(
        this.accept
          .split(",")
          .map((mime: string) => {
            return (
              MIME_TYPES.find((type: { mime: string; extension: string }) => {
                return type.mime === mime.trim();
              })?.extension ?? ""
            );
          })
          .join(", ")
      );
    }

    return ret.join("; ");
  }
}
