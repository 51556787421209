
import { Component, Prop, Vue } from "vue-property-decorator";
import ReminderForm from "@/components/reminder/ReminderForm.vue";
import IReminder from "@/interfaces/IReminder";

@Component({
  components: { ReminderForm },
})
export default class ReminderCardView extends Vue {
  @Prop({ required: true })
  protected refDate!: Date;

  @Prop({ required: false, default: "create" })
  protected mode!: string;

  @Prop({ required: false, default: null })
  protected reminder!: IReminder | null;

  @Prop({ required: false, default: null })
  protected infoTextProposal!: string | null;

  protected onSubmit(e: any): void {
    this.$emit("submit", e, this.mode);
  }

  mounted() {
    console.debug("ReminderCardView", this.mode);
  }
}
