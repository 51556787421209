
import { Component, Prop, Vue } from "vue-property-decorator";
import IUser from "@/mixins/interface/IUser";

@Component({
  components: {},
})
export default class BlameLine extends Vue {
  @Prop({ required: true })
  protected item!: any;
}
