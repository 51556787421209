
import { Component, Watch } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import CustomerContractForm from "./CustomerContractForm.vue";
import { CustomerContract } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { CustomerContractStoreMixin } from "@/mixins/Stores/CustomerContractStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import BlameLine from "@/components/common/BlameLine.vue";
import ReminderForm from "@/components/reminder/ReminderForm.vue";
import ReminderCardView from "@/components/reminder/ReminderCardView.vue";
import { ReminderStoreMixin } from "@/mixins/Stores/ReminderStoreMixin";
import IReminder from "@/interfaces/IReminder";
import i18n from "@/locales";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";
import Upload from "@/components/upload/Upload.vue";
import Documents from "@/components/media/Documents.vue";
import IMedia from "@/interfaces/IMedia";
import Events from "@/echo/Events";
import Str from "@/helper/Str";
import CustomerContractShow from "@/views/Customer/Contract/CustomerContractShow.vue";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: {
    CustomerContractShow,
    Documents,
    ReminderForm,
    BlameLine,
    CustomerContractForm,
    Upload,
  },
  mixins: [UpdateMixin],
})
export default class ContractUpdate extends mixins(
  CustomerContractStoreMixin,
  ReminderStoreMixin
) {
  protected reminderModal: any;

  protected isReminderActive = false;

  protected documents: Array<IMedia> | null = null;

  protected isOpen = 1;

  protected recentlyUploadedMediaIds: Array<number> = [];

  protected get viewMode(): string {
    return String(this.$route?.query?.mode ?? "show");
  }

  @Watch("getReminderItem", { immediate: true, deep: true })
  protected onReminderItemChanged(item: IReminder | null): void {
    if (item) {
      this.isReminderActive = !!item?.is_active;
    }
  }

  protected onReminderActiveChanged(active: boolean) {
    let dialogParams: any = {
      title: String(
        i18n.t("modules.customer.contract.reminder_activate_dialog.title")
      ),
      message: String(
        i18n.t("modules.customer.contract.reminder_activate_dialog.message")
      ),
      type: "is-success",
      hasIcon: true,
      ariaRole: "alertdialog",
      ariaModal: true,
      onConfirm: () => {
        this.updateReminderItemAction({
          resource: this.reminderResource,
          id: this.getReminderItem.id,
          data: {
            is_active: active,
          },
        });
      },
      onCancel: () => {
        this.isReminderActive = !this.isReminderActive;
      },
    };

    if (!active) {
      dialogParams.title = String(
        i18n.t("modules.customer.contract.reminder_deactivate_dialog.title")
      );
      dialogParams.message = String(
        i18n.t("modules.customer.contract.reminder_deactivate_dialog.message")
      );
      dialogParams.type = "is-warning";
    }

    this.$buefy.dialog.confirm(dialogParams);
  }

  protected registerListeners(): void {
    this.$echo
      .channel(Events.MEDIA_CONTRACT.channel)
      .listen(Events.MEDIA_CONTRACT.listen_to, (e: any) => {
        if (
          this.recentlyUploadedMediaIds.includes(e.media.id) &&
          e.conversionName === "thumb"
        ) {
          this.isOpen = 1;
          this.fetchDocuments();
          this.recentlyUploadedMediaIds.splice(
            this.recentlyUploadedMediaIds.indexOf(e.media.id),
            1
          );
        }
      });
  }

  protected init(): void {
    this.recentlyUploadedMediaIds = [];
    this.registerListeners();
    this.findOneReminderItemAction({
      resource: this.reminderResource,
    });

    this.findOneCustomerContractItemAction({
      id: this.$route.params.contract_id,
      resource: this.resource,
    });

    this.fetchDocuments();
  }

  protected fetchDocuments(): void {
    this.$apiService
      .get(
        CustomerContract.getMediasUrl(
          Number(this.$route?.params?.id),
          Number(this.$route.params.contract_id)
        )
      )
      .then((response: any) => {
        if (response.data) {
          this.documents = response.data;
        }
      });
  }

  public get resource(): string {
    return String(CustomerContract.resource).replace(
      "<customer_id>",
      this.$route?.params?.id
    );
  }

  public updateItemAction(options: ICrudOptions): any {
    this.updateCustomerContractItemAction(options);
  }

  public get descriptionField(): string {
    return CustomerContract.description_field;
  }

  protected get reminderResource(): string {
    return [
      this.resource,
      //this.getCustomerContractItem.id,
      this.$route.params.contract_id,
      "reminder",
    ].join("/");
  }

  protected get uploadResource(): string {
    return [this.resource, this.$route.params.contract_id, "upload"].join("/");
  }

  protected showReminderModal(
    mode = "create",
    reminder: IReminder | null = null
  ): void {
    this.reminderModal = this.$buefy.modal.open({
      parent: this,
      component: ReminderCardView,
      props: {
        refDate: this.getCustomerContractItem?.ends_at,
        infoTextProposal: String(
          this.$t("modules.customer.contract.reminder_info_text_proposal", {
            ends_at: this.$moment(
              new Date(this.getCustomerContractItem.ends_at)
            ).format(DEFAULT_DATE_FORMAT),
          })
        ),
        mode: mode,
        reminder: reminder,
      },
      events: {
        submit: this.onReminderSubmit,
      },
      hasModalCard: true,
      trapFocus: true,
    });
  }

  protected onReminderSubmit(data: any, mode: string) {
    data["data_array"] = {
      view_contract_url: this.$route.fullPath,
    };

    if (mode === "update") {
      this.updateReminderItemAction({
        resource: this.reminderResource,
        id: data.id,
        data: data,
      }).then(() => {
        if (this.reminderModal) {
          this.reminderModal.close();
        }
      });
      return;
    }

    this.createReminderItemAction({
      resource: this.reminderResource,
      data: data,
    }).then(() => {
      if (this.reminderModal) {
        this.reminderModal.close();
      }
    });
  }

  protected onDocumentUploaded(document: IMedia): void {
    this.recentlyUploadedMediaIds.push(document.id);
  }

  protected onDocumentRemoved(document: IMedia) {
    this.fetchDocuments();
  }
}
