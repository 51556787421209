import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Reminder } from "@/config/Modules";

const ReminderModule = namespace(Reminder.store);

@Component({})
export class ReminderStoreMixin extends Vue {
  /**
   * Actions
   */
  @ReminderModule.Action("create")
  protected createReminderItemAction: any;

  @ReminderModule.Action("findOne")
  protected findOneReminderItemAction: any;

  @ReminderModule.Action("update")
  protected updateReminderItemAction: any;

  @ReminderModule.Action("findAll")
  protected findAllReminderItemsAction: any;

  @ReminderModule.Action("resetSave")
  protected resetSaveReminderAction: any;

  @ReminderModule.Action("resetList")
  protected resetListReminderAction: any;

  @ReminderModule.Action("del")
  protected delReminderItemAction: any;

  @ReminderModule.Action("findAllSubresource")
  protected findAllSubresourceReminderItemsAction: any;

  @ReminderModule.Action("setDataList")
  protected setDataListReminderAction: any;

  @ReminderModule.Action("upload")
  protected uploadReminderAction: any;

  /**
   * Getters
   */
  @ReminderModule.Getter("getError")
  protected getReminderError: any;

  @ReminderModule.Getter("getIsLoading")
  protected getReminderIsLoading: any;

  @ReminderModule.Getter("getIsSaving")
  protected getReminderIsSaving: any;

  @ReminderModule.Getter("getDataItem")
  protected getReminderItem: any;

  @ReminderModule.Getter("getSuccess")
  protected getReminderSuccess: any;

  @ReminderModule.Getter("getDataList")
  protected getReminderList: any;

  @ReminderModule.Getter("getTotal")
  protected getReminderTotal: any;

  @ReminderModule.Getter("getDeletedSuccess")
  protected getReminderDeletedSuccess: any;

  @ReminderModule.Getter("getValidationErrors")
  protected getReminderValidationErrors: any;

  /**
   * Mutations
   */
  @ReminderModule.Mutation("IS_LOADING")
  protected setReminderLoading: any;

  @ReminderModule.Mutation("SET_IS_SAVING")
  protected setReminderIsSaving: any;

  @ReminderModule.Mutation("SET_ERROR")
  protected setReminderError: any;

  @ReminderModule.Mutation("SET_SUCCESS")
  protected setReminderSuccess: any;

  @ReminderModule.Mutation("SET_DATA_ITEM")
  protected setReminderItem: any;

  @ReminderModule.Mutation("SET_DATA_LIST")
  protected setReminderList: any;

  @ReminderModule.Mutation("SET_TOTAL")
  protected setReminderTotal: any;

  @ReminderModule.Mutation("SET_DELETED_SUCCESS")
  protected setReminderDeletedSuccess: any;

  @ReminderModule.Mutation("SET_VALIDATION_ERRORS")
  protected setReminderValidationErrors: any;

  // Additional...
}
