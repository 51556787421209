import { render, staticRenderFns } from "./InfoTable.vue?vue&type=template&id=6f87c26f&scoped=true&"
import script from "./InfoTable.vue?vue&type=script&lang=ts&"
export * from "./InfoTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f87c26f",
  null
  
)

export default component.exports